import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Layout from "../components/Layout";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Link, navigate } from "gatsby";
import { toast } from "react-toastify";
import { graphql } from "../helpers/fetch";

const schema = yup
  .object()
  .shape({
    firstName: yup.string().required("First name is required."),
    lastName: yup.string().required("Last name is required."),
    phone: yup.string().required("Phone is required."),
    email: yup.string().email().required("Email is required."),
    password: yup
      .string()
      .required("Password is required.")
      .min(8, "Password is too short - should be 8 chars minimum.")
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, "Password must have minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character."),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
    subscribed: yup.bool(),
  })
  .required();

const Register = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [postErrors, setPostErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(async (data) => {
    setLoading(true)
    const { data: res, errors: resErrors } = await graphql(
      `${process.env.GATSBY_CMS_URL}/graphql`,
      `
        mutation {
          createCustomerV2(
            input: {
              firstname: "${data.firstName}"
              lastname: "${data.lastName}"
              email: "${data.email}"
              password: "${data.password}"
              is_subscribed: ${data.subscribed}
            }
          ) {
            customer {
              firstname
              lastname
              email
              is_subscribed
            }
          }
        }
      `
    );

    if (!resErrors) {
      toast("Your account is created.");
      setTimeout(() => navigate("/login"), 2000);
    } else {
      setPostErrors(resErrors.map((e) => e.message));
    }
    setLoading(false)
  }, []);

  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [token]);

  return (
    <Layout>
      <div className="login-page">
        <Container>
          <Row className="justify-content-center">
            <Col
              xs={12}
              sm={10}
              md={8}
              lg={7}
              xl={6}
              xxl={5}
              className="d-flex flex-column align-items-center"
            >
              <h1 className="h2">Register an account</h1>
              <form onSubmit={handleSubmit(onSubmit)} className="w-100">
                <Form.Group
                  className="form-group"
                  controlId="formRegisterFirstName"
                >
                  <Form.Label className="text-sm">First Name *</Form.Label>
                  <input
                    type="text"
                    id="firstName"
                    className="form-control"
                    placeholder="Enter first name"
                    {...register("firstName")}
                  />
                  {!!errors.firstName && (
                    <p className="text-danger small mt-1">
                      {errors.firstName.message}
                    </p>
                  )}
                </Form.Group>

                <Form.Group
                  className="form-group"
                  controlId="formRegisterLastName"
                >
                  <Form.Label className="text-sm">Last Name *</Form.Label>
                  <input
                    type="text"
                    id="lastName"
                    className="form-control"
                    placeholder="Enter last name"
                    {...register("lastName")}
                  />
                  {!!errors.lastName && (
                    <p className="text-danger small mt-1">
                      {errors.lastName.message}
                    </p>
                  )}
                </Form.Group>

                <Form.Group
                  className="form-group"
                  controlId="formRegisterPhone"
                >
                  <Form.Label className="text-sm">Phone *</Form.Label>
                  <input
                    type="text"
                    id="phone"
                    className="form-control"
                    placeholder="Enter phone number"
                    {...register("phone")}
                  />
                  {!!errors.phone && (
                    <p className="text-danger small mt-1">
                      {errors.phone.message}
                    </p>
                  )}
                </Form.Group>

                <Form.Group
                  className="form-group"
                  controlId="formRegisterEmail"
                >
                  <Form.Label className="text-sm">Email Address *</Form.Label>
                  <input
                    type="email"
                    id="email"
                    className="form-control"
                    placeholder="Enter email"
                    {...register("email")}
                  />
                  {!!errors.email && (
                    <p className="text-danger small mt-1">
                      {errors.email.message}
                    </p>
                  )}
                </Form.Group>

                <Form.Group
                  className="form-group"
                  controlId="formRegisterPassword"
                >
                  <Form.Label className="text-sm">Password *</Form.Label>
                  <input
                    type="password"
                    id="password"
                    className="form-control"
                    {...register("password")}
                  />
                  {!!errors.password && (
                    <p className="text-danger small mt-1">
                      {errors.password.message}
                    </p>
                  )}
                </Form.Group>

                <Form.Group
                  className="form-group"
                  controlId="formRegisterConfirmPassword"
                >
                  <Form.Label className="text-sm">
                    Confirm Password *
                  </Form.Label>
                  <input
                    type="password"
                    id="passwordConfirmation"
                    className="form-control"
                    {...register("passwordConfirmation")}
                  />
                  {!!errors.passwordConfirmation && (
                    <p className="text-danger small mt-1">
                      {errors.passwordConfirmation.message}
                    </p>
                  )}
                </Form.Group>

                <Form.Group
                  className="form-group"
                  controlId="formRegisterLastName"
                >
                  <div className="group-control">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        id="subscribed"
                        className="form-check-input"
                        {...register("subscribed")}
                      />
                      <label
                        title=""
                        htmlFor="subscribed"
                        className="form-check-label"
                      >
                        I agree to receive email marketing communications from
                        Grocorp
                      </label>
                      {!!errors.subscribed && (
                        <p className="text-danger small">
                          {errors.subscribed.message}
                        </p>
                      )}
                    </div>
                  </div>
                </Form.Group>

                {postErrors.length > 0 && (
                  <div className="group-control">
                    {postErrors.map((er) => (
                      <p key={er} className="text-danger small">
                        {er}
                      </p>
                    ))}
                  </div>
                )}
                <Button
                  variant="outline-primary"
                  className="w-100"
                  type="submit"
                >
                  {loading ? "Registering" : "Register"}
                </Button>
              </form>

              <p className="mb-4">
                Already a member? <Link to="/login">Login</Link>
              </p>
              <Link to="/" className="return">
                Return home
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};

export default Register;
